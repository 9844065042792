export function getStudentViewURL(
  url,
  studioUrl,
  path,
  magicCode,
  forceMarvelousDomain = false
) {
  let magicCodeParam = '';
  const DEFAULT_DOMAIN_HEYMARVELOUS = 'heymarvelous.com';
  const DEFAULT_DOMAIN_NAMASTREAM = 'namastream.com';
  const isSubdomainsServer =
    process.env.VUE_APP_IS_SUBDOMAINS_SERVER === 'true';
  const areStudioSubdomainsEnabled =
    process.env.VUE_APP_STUDIO_ON_SUBDOMAINS === 'true';
  if (areStudioSubdomainsEnabled || isSubdomainsServer) {
    if (magicCode)
      magicCodeParam = `${
        path && path.indexOf('?') !== -1 ? '&' : '?'
      }magic_code=${magicCode}`;
    let domain =
      url.hostname.split('.').splice(1).join('.') ||
      DEFAULT_DOMAIN_HEYMARVELOUS;
    if (forceMarvelousDomain) {
      // in student view, prevent the link to point to a subdomain.custom-domain.com and force heymarvelous.com domain
      if (
        ![DEFAULT_DOMAIN_NAMASTREAM, DEFAULT_DOMAIN_HEYMARVELOUS].some(
          item => item === domain
        )
      ) {
        domain = DEFAULT_DOMAIN_HEYMARVELOUS;
      }
    } else {
      // handle custom domain...
      if (
        ![DEFAULT_DOMAIN_NAMASTREAM, DEFAULT_DOMAIN_HEYMARVELOUS].some(
          item => item === domain
        )
      ) {
        return `${url.origin}${path}${magicCodeParam}`;
      }
    }
    return `https://${studioUrl}.${domain}${path}${magicCodeParam}`;
  } else {
    if (magicCode)
      magicCodeParam = `${
        path && path.indexOf('?') !== -1 ? '&' : '?'
      }magic_code=${magicCode}`;
    return `${url.origin}/${studioUrl}${path}${magicCodeParam}`;
  }
}

export function getRelativeUrl(path, studioUrl) {
  const startsWith = path.startsWith('/' + studioUrl);
  if (!startsWith) {
    return startsWith + path;
  }
  return path;
}
